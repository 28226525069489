<script lang="ts" setup>
import { ref } from "vue";
import ResourcesGrid from "@/components/iam/ResourcesGrid.vue";

const addLink = ref({ name: "IAM-ResourceAdd", params: { resourceId: "-", addModus: true } });
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <resources-grid :addLink="addLink"></resources-grid>
  </v-container>
</template>
